<template>
  <div class="message_board">
    <div class="message">
      <Editor class="editor" :value="message" @input="getEditorData" />
      <el-button class="mt-3" @click="sendMessage" type="primary">發送留言</el-button>
    </div>
    <div class="messageTable">
      <el-table :max-height="$store.getters.mobileSize ? '300' : '600'" class="multipleTable" ref="multipleTable" :data="displayData" :default-sort="{ prop: 'createdDateTime', order: 'descending' }">
        <el-table-column type="expand" width="30">
          <template #default="props">
            <div class="my-3 messageContent">
              留言內容：
              <div class="content" v-html="props.row.message"></div>
            </div>
            <div class="my-3 messageContent">
              回覆內容：
              <div class="content" v-html="props.row.replyMessage"></div>
            </div>
          </template>
        </el-table-column>
        <el-table-column sortable prop="createdDateTime" sort-by="createdDateTime" label="留言日期" :width="$store.getters.mobileSize ? '' : '300'">
          <template #default="scope">
            {{ scope.row.createdDateTime.split("T")[0] }} <span>{{ scope.row.createdDateTime.split("T")[1].split(".")[0] }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="isReply" label="處理進度" sortable :width="$store.getters.mobileSize ? '' : '150'">
          <template #default="scope">
            <el-tag :type="scope.row.isReply ? 'success' : 'warning'" disable-transitions>{{ scope.row.isReply ? "已處理" : "尚未處理完成" }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column>
          <template #header>
            <el-input v-model="search" size="small" placeholder="搜尋關鍵字" />
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import Editor from "../../../components//Common/Editor.vue";

export default {
  name: "MessageBoard",
  components: {
    Editor,
  },
  data() {
    return {
      message: "",
      messageList: [],
      page: 1,
      pageSize: 5,
      pageTotal: 0,
      search: "",
    };
  },
  async mounted() {
    const vm = this;
    this.log_PageInfo("CommentFeedback", "7");
    vm.initData();
  },
  computed: {
    displayData() {
      let displayData = this.searchData(this.messageList);
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      // this.pageTotal = displayData.length;
      // return displayData.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page);
      return displayData;
    },
  },
  methods: {
    getEditorData(input) {
      this.message = input;
    },
    initData() {
      const vm = this;
      vm.fetchAPI("get", `${process.env.VUE_APP_URL_API}/Member/MessageBoard`)
        .then((res) => {
          console.log(res.data);
          vm.messageList = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    sendMessage() {
      const vm = this;
      const data = {
        MemberId: vm.currentUser().id,
        Message: vm.message,
      };
      if (data.Message == "" || data.Message == null) {
        vm.notify("warning", `留言回饋不可為空白！`);
        return;
      }
      vm.fetchAPI("post", `${process.env.VUE_APP_URL_API}/Member/MessageBoard`, data)
        .then((res) => {
          console.log(res.data);
          vm.message = "";
          vm.messageList.unshift(res.data);
          vm.notify("success", `留言回饋成功！`);
        })
        .catch((err) => {
          console.log(err.response.data);
          vm.notify("error", `留言回饋失敗！`);
        });
    },
    handleCurrentChange(val) {
      this.page = val;
    },
    searchData(table) {
      return table.filter((data) => !this.search || data.message.toLowerCase().includes(this.search.toLowerCase()) || data.replyMessage.toLowerCase().includes(this.search.toLowerCase()) || data.createdDateTime.includes(this.search.toLowerCase()));
    },
    trimMessage(message) {
      if (message.length > 15) {
        return message.substring(0, 15) + "...";
      }
      return message;
    },
  },
};
</script>

<style lang="scss" scoped>
.message_board {
  width: 80%;
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0 auto;
  .message {
    width: 60%;
    margin: 2rem;
  }
  .messageTable {
    flex-grow: 1;
    width: 80%;
    margin: 2rem;
    overflow-y: auto;
  }
  .pagination {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 3rem;
    .el-pagination {
      align-items: flex-end;
    }
  }
  .expended {
    .el-table__cell {
      padding: 0 2rem;
      background-color: #000;
    }
  }
  .messageContent {
    margin-left: 2.5rem;
    margin-right: 0.5rem;
    .content {
      padding-top: 0.5rem;
      word-wrap: break-word;
      line-height: 1.5;
    }
  }
}
:deep .editor .tox {
  z-index: 0 !important;
}
@media screen and (max-width: 768px) {
  .message_board {
    flex-direction: column;
    width: 100%;
    .message {
      margin-top: 2rem;
      width: 90%;
    }
    .messageTable {
      margin: 0;
      width: 90% !important;
    }
  }
}
</style>
